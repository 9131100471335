import { useState, useEffect, useContext } from "react";
import { BuilderQueryContext } from "../context/BuilderQueryContext.js";
import CardSearch from "../Cards/CardSearch.js";


function CardTextInput({
    booster_sets,
    images,
    setImages
}) {

    const {
        query,
        sortState,
        boosterSet,
        rarity,
        showMore,
        setShowMore
    } = useContext(BuilderQueryContext)

    const [cards, setCards] = useState([]);
    const [noCards, setNoCards] = useState(false);

    const [showSearch, setShowSearch] = useState(false)
    const [showPool, setShowPool] = useState(false);

    const getCards = async() =>{
        const response = await fetch("https://pm-deck-react-only.onrender.com/cards")
        const data = await response.json();
        console.log(data)
        if (data.length == 0 ) {
            setNoCards(true)
        }
        const sortedCards = [...data].sort(sortMethods[sortState].method);
        setCards(sortedCards);
    };

    useEffect(() => {
        window.scroll(0, 0);
        document.body.style.overflow = 'auto';
        getCards();
        document.title = "Card Tips Create - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    },[]);

    const sortMethods = {
        none: { method: (a,b) => a.card_number - b.card_number },
        newest: { method: (a,b) => b.id.localeCompare(a.id) },
        oldest: { method: (a,b) => a.id.localeCompare(b.id) },
        name: { method: (a,b) => a.name.localeCompare(b.name) },
        card_number: { method: (a,b) => a.card_number - b.card_number },
        enthusiasm_highest: { method: (a,b) => b.enthusiasm - a.enthusiasm },
        enthusiasm_lowest: { method: (a,b) => a.enthusiasm - b.enthusiasm },
    };

    const all_cards = cards.filter(card => card.name.toLowerCase().includes(query.cardName.toLowerCase()))
        .filter(card => (card.effect_text + card.second_effect_text).toLowerCase().includes(query.cardText.toLowerCase()))
        .filter(card => card.card_number.toString().includes(query.cardNumber))
        .filter(card => card.hero_id.toLowerCase().includes(query.heroID.toLowerCase()))
        .filter(card => card.series_name.toLowerCase().includes(query.series.toLowerCase()))
        .filter(card => card.card_number >= query.startingNum)
        .filter(card => query.type? card.card_type.some(type => type.toString() == query.type):card.card_type)
        .filter(card => card.card_class.includes(query.cardClass))
        .filter(card => query.extraEffect? card.extra_effects.some(effect => effect.toString() == query.extraEffect):card.extra_effects)
        .filter(card => query.reaction? card.reactions.some(reaction => reaction.toString() == query.reaction):card.reactions)
        .filter(card => query.tag? card.card_tags.some(tag => tag.toString() == query.tag):card.card_tags)
        .filter(card => boosterSet && !rarity ? boosterSet.all_cards.includes(card.card_number):card.card_number)
        .filter(card => boosterSet && rarity ? boosterSet[rarity].includes(card.card_number):card.card_number)
        .sort(sortMethods[sortState].method)

    const handleShowMore = (event) => {
        setShowMore(showMore + 20)
    };

    const handleClick = (card) => {
        const newImages = [...images]
        newImages.push({
                keyName:"",
                src: card.picture_url,
                caption:"",
                link:`https://www.jothplaymaker.com/cards/${card.card_number}`,
                order: 0,
                alt_text:card.name,
            })
        setImages(newImages)
    }

    const handleShowPool = (event) => {
        setShowPool(!showPool);
        setShowSearch(!showSearch)
    };

    const preprocessText = (text) => {
        return text.split("//").join("\n");
    };

    const handleAddImage = (card) => {
        const newImages = [...images]
        newImages.push({
                keyName:"",
                src: "",
                caption:"",
                link:"",
                order:"",
                alt_text:"",
            })
        setImages(newImages)
    }

    const isQueryEmpty = Object.values(query).every((value) => value === "");


    return (
        <>
            {showSearch ?
                <div className="flex-column-full">
                    <br/>
                    <CardSearch
                        boosterSets={booster_sets}
                    />
                </div>: null
            }

            <div className={showPool ? "rarities2" : "no-rarities"}>
                <div style={{marginLeft: "0px"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <h2
                            className="left"
                            style={{margin: "1% 0px 1% 20px", fontWeight: "700"}}
                        >Card Pool</h2>
                        <img className="logo" src="https://i.imgur.com/YpdBflG.png" alt="cards icon"/>
                        {all_cards.length > 0 ?
                            <h5
                                className="left db-pool-count"
                            >{all_cards.length}</h5>:
                            null}
                        { showPool ?
                            <h5 className="left db-pool-count"
                                onClick={() => handleShowPool()}>
                                    &nbsp;[Hide]
                            </h5> :
                            <h5 className="left db-pool-count"
                                onClick={() => handleShowPool()}>
                                &nbsp;[Show]
                            </h5>}
                    </div>
                    <div className={showPool ? "scrollable" : "hidden2"}>
                        <div style={{margin: "8px"}}>

                        { all_cards.length == 0 && isQueryEmpty && !noCards?
                            <div className="loading-container">
                                <div className="loading-spinner"></div>
                            </div> :
                        null}

                        <div className="card-pool-fill">
                            {all_cards.slice(0, showMore).map((card) => {
                                return (
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <img
                                            onClick={() => handleClick(card)}
                                            className="builder-card glow3"
                                            title={`${card.name}\n${preprocessText(card.effect_text)}\n${card.second_effect_text ? preprocessText(card.second_effect_text) : ""}`}
                                            src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                            alt={card.name}/>
                                    </div>
                                );
                            })}
                        </div>
                        </div>
                        {showMore < all_cards.length ?
                            <button
                                style={{ width: "97%", margin:".5% 0% .5% 1.5%"}}
                                onClick={handleShowMore}>
                                Show More Cards ({all_cards.length - showMore} Remaining)
                            </button> : null }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardTextInput;
