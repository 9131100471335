import { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate} from 'react-router-dom';
import RelatedCardModal from "./RelatedCardModal";
import BackButton from "../display/BackButton";
import card_categories from "../database/card_categories.json";
import ImageWithoutRightClick from "../display/ImageWithoutRightClick";
import ErrorPage from "../display/ErrorPage";
import { AuthContext } from "../context/AuthContext";
import CardEditModal from "./CardEditModal";
import SearchGroupPanel from "./SearchGroupPanel";


function CardDetailPage({
    // cards,
    card_types,
    card_tags,
    extra_effects,
    reactions
}) {
    const { card_number } = useParams()
    const { account } = useContext(AuthContext)

    const [card, setCard] = useState({
        name: "",
        card_class: "",
        hero_id: "",
        series_name: "",
        seriesNames: [],
        card_number: "",
        enthusiasm: "",
        effect_text: "",
        second_effect_text: "",
        effectText: [],
        secondEffectText: [],
        illustrator: "",
        picture_url: "",
        file_name: "",
        card_type: [],
        extra_effects: [],
        reactions: [],
        card_tags: [],
    });
    const [noCard, setNoCard] = useState(false)
    const [cards, setCards] = useState([])
    const [relatedCardsList, setRelatedCardsList] = useState([])
    const [searchGroups, setSearchGroups] = useState([])
    const [noCardTip, setNoCardTip] = useState(true)
    const [tipCount, setTipCount] = useState(null)
    const [noCardRuling, setNoCardRuling] = useState(true)

    const getCard = async() =>{
        // const cardData = cards.find(card => card.card_number.toString() === card_number)
        const cardResponse = await fetch(`https://pm-deck-react-only.onrender.com/cards/${card_number}`)
        // const cardResponse = await fetch(`http://localhost:4000/cards/${card_number}`)
        if (cardResponse.ok) {
            const cardData = await cardResponse.json()
            cardData["seriesNames"] = cardData.series_name.split("//")
            cardData["effectText"] = cardData.effect_text.split("//")
            if (cardData.second_effect_text){
                cardData["secondEffectText"] = cardData.second_effect_text.split("//")
            }
            setCard(cardData);
        } else {
            setNoCard(true)
        }
    };

    const getRelatedCards = async() => {
        if (card.hero_id) {
            // const cardsResponse = await fetch(`http://localhost:4000/search_groups`)
            const cardsResponse = await fetch("https://pm-deck-react-only.onrender.com/cards")
            const cardsData = await cardsResponse.json()
            if (cardsData) {
                setCards(cardsData)
                const relatedCardsData = cardsData.filter(relatedCard => (card.hero_id === relatedCard.hero_id) && relatedCard.card_number !== card.card_number)
                setRelatedCardsList(relatedCardsData.sort((a,b) => a.card_number - b.card_number))
            }
        }
    }

    const getSearchGroups = async() => {
        if (card_number) {
            // const groupsResponse = await fetch(`http://localhost:4000/search_groups/cards/${card_number}`)
            const groupsResponse = await fetch(`https://pm-deck-react-only.onrender.com/search_groups/cards/${card_number}`)
            const groupsData = await groupsResponse.json()
            if (groupsData) {
                console.log(groupsData)
                setSearchGroups(groupsData.sort((a,b) => a.name.localeCompare(b.name)))
                console.log(groupsData)
            }
        }
    }

    const getCardTip = async() =>{
        const cardTipResponse = await fetch(`https://pm-deck-react-only.onrender.com/card_tips/${card_number}`)
        // const cardResponse = await fetch(`http://localhost:4000/cards/${card_number}`)
        if (!cardTipResponse.ok) {
            setNoCardTip(true)
        } else {
            const cardTipData = await cardTipResponse.json()
            setNoCardTip(false)
            setTipCount(cardTipData.content.split("//").length)
        }
    };

    const getCardRuling = async() =>{
        const cardRulingResponse = await fetch(`https://pm-deck-react-only.onrender.com/card_rulings/${card_number}`)
        // const cardResponse = await fetch(`http://localhost:4000/cards/${card_number}`)
        if (!cardRulingResponse.ok) {
            setNoCardRuling(true)
        } else {
            setNoCardRuling(false)
        }
    };

    const card_type = card_types.find(card_type => card?.card_type[0] === card_type?.type_number)

    const extra_effects_list = []
    for (let extra_effect of extra_effects) {
        if (card.extra_effects.includes(extra_effect.effect_number) ) {
            extra_effects_list.push(extra_effect)
        }
    }

    const reaction_counts = {}
    for (let reaction_number of card.reactions) {
        const reaction = reactions.find(reaction => reaction.reaction_number === reaction_number)
            !reaction_counts[reaction.name]?
                reaction_counts[reaction.name] = {
                    info: reaction,
                    count: 1,

                }:
                reaction_counts[reaction.name]["count"]++

        console.log(reaction_counts)
    }

    const reactions_list = Object.values(reaction_counts)

    const reactionRules = (reaction) => {
        const rules = reaction.info.rules.replace("{count}", reaction.count.toString())
        return rules
    }

    const card_tags_list = []
    for (let card_tag of card_tags) {
        if (card.card_tags.includes(card_tag.tag_number) ) {
            card_tags_list.push(card_tag)
        }
    }

    const navigate = useNavigate()

    const getRandomCard = async() =>{
        const randomIndex = Math.floor(Math.random() * cards.length);
        const randomCard = cards[randomIndex].card_number;
        navigate(`/cards/${randomCard}`);
    }

    useEffect(() => {
        window.scroll(0, 0);
        getCard();
        getSearchGroups();
        getCardTip();
        getCardRuling();
    }, [card_number]);

    useEffect(() => {
        getRelatedCards();
        document.title = `${card.name} - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    }, [card])

    const matchSeries = (line) => {
        const cardCategory = card_categories?.find(category => category.name === line)
        // console.log(card_categories)
        return cardCategory?.id
    };

    const matchClass = (card_class) => {
        const cardCategory = card_categories?.find(category => category.name === card_class)
        // console.log(card_categories)
        return cardCategory?.id
    }


    return (
        <>
            {!noCard?
                <div className="white-space flex-column-items">
                    <h1 className="margin-bottom-n10 cd-title-3"
                        style={{margin: "0px 10px -13px 10px"}}>
                            {card.name}
                    </h1>
                    <div className="cd-container space-around media-inline"
                        style={{maxWidth: "1300px", width: "100%"}}
                    >
                        <div className="cd-container-child">
                            <div className="cd-inner media-display">
                                <img
                                    className="cd-card wide100 heightAuto"
                                    src={card.picture_url ? card.picture_url : "https://i.imgur.com/krY25iI.png"}
                                    alt={card.name}/>
                            </div>
                            <div className="none" style={{margin: "5% 0%"}}>
                                <h1 className="centered-h1">Related Cards</h1>
                                <div className="cd-inner">
                                    <div className="cd-inner card-list3" style={{width: "480px"}}>
                                        {relatedCardsList.slice(0,9).map((relatedCard) => {
                                            return (
                                                <NavLink to={`/cards/${relatedCard.card_number}`}>
                                                        <img
                                                            className="cd-related-card"
                                                            title={relatedCard.name}
                                                            src={relatedCard.picture_url ? relatedCard.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                            alt={relatedCard.name}/>
                                                </NavLink>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="cd-inner" style={{marginTop: "3%"}}>
                                    <button
                                        className="left button100 heightNorm"
                                        style={{ textAlign: "center"}}
                                        onClick={getRandomCard}
                                    >
                                        Random Card
                                    </button>
                                    {relatedCardsList.length > 9?
                                        <RelatedCardModal
                                            relatedCardsList={relatedCardsList}
                                        />: null
                                    }
                                    { account && account.roles.includes("admin")?
                                        <CardEditModal
                                            cardData={card}
                                            cardTypes={card_types}
                                            cardTags={card_tags}
                                            extraEffects={extra_effects}
                                            reactionsData={reactions}
                                        />:
                                    null}
                                </div>
                            </div>
                        </div>

                        <div className="cd-container-child">
                            <div className="cd-inner2 media-display">
                                <div>
                                    <div className="cd-info">
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Type</h4>
                                            <NavLink to={`/cardtypes/${card_type?.id}`} className="nav-link2 glow2">
                                                    <h5 title={card_type?.rules}
                                                        style={{fontWeight: "400", margin: "18px 12px"}}
                                                        >{card_type?.name} *
                                                    </h5>
                                            </NavLink>
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Class</h4>
                                            { card.card_class?
                                                <NavLink to={`/cardcategories/${matchClass(card.card_class)}`} className="nav-link2 glow2">
                                                    <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.card_class ? `${card.card_class} *` : "n/a"}</h5>
                                                </NavLink>:
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.card_class ? card.card_class : "n/a"}</h5>
                                            }
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Reactions</h4>
                                            {reactions_list.length ? (
                                                reactions_list.map((reaction) => (
                                                    <NavLink to={`/reactions/${reaction.info.id}`} className="nav-link2 glow2">
                                                        <h5 title={reactionRules(reaction)} style={{fontWeight: "400", margin: "18px 12px"}} key={reaction.info.name}>
                                                            {reaction.info.name} {reaction.count} *
                                                        </h5>
                                                    </NavLink>
                                                ))
                                            ) : (
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>n/a</h5>
                                            )}
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Enthusiasm</h4>
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.enthusiasm ? card.enthusiasm : "n/a"}</h5>
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Tags</h4>
                                            {card_tags_list[0]?.tag_number !== 1000?
                                                <>
                                                    {card_tags_list.map((card_tag) => {
                                                        return (
                                                            <NavLink to={`/cardtags/${card_tag.id}`} className="nav-link2 glow2">
                                                                <h5 title={card_tag.rules}
                                                                    style={{fontWeight: "400", margin: "18px 12px"}}>
                                                                        {
                                                                            card_tag.tag_number === "1000" ?
                                                                            card_tag.name : card_tag.name + " *"}
                                                                </h5>
                                                            </NavLink>

                                                        );
                                                    })}
                                                </>:
                                                    <h5 style={{fontWeight: "400", margin: "18px 12px"}}>
                                                        {card_tags_list[0].name}
                                                    </h5>
                                            }

                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Series</h4>
                                                {card.seriesNames.map((line) =>
                                                    <NavLink to={`/cardcategories/${matchSeries(line)}`} className="nav-link2 glow2">
                                                        <h5 style={{fontWeight: "400", margin: "18px 12px"}}>
                                                        {line} *</h5>
                                                    </NavLink>)
                                                }
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Card Number</h4>
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.card_number}</h5>
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Hero ID</h4>
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.hero_id}</h5>
                                        </div>
                                        <div className={card.card_class ? card.card_class : "NoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "10px 0px 0px 12px"}}>Illustrator</h4>
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>{card.illustrator}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div className={card.card_class ? `big${card.card_class}` : "bigNoClass"}>
                                            <h4 style={{fontWeight: "600", margin: "12px"}}>Card Effect</h4>

                                            {card.effectText.map((line) =>
                                                <h5 style={{fontWeight: "400", margin: "18px 12px"}}>
                                                    {line}</h5>)}


                                            {card.second_effect_text && (
                                                <div className="borderBlack">

                                                {card.secondEffectText.map((line) =>
                                                    <h5 style={{fontWeight: "400", margin: "18px 12px"}}>
                                                        {line}</h5>)}


                                                </div>
                                            )}
                                            {extra_effects_list.length ? (
                                            <>
                                                <h4 style={{fontWeight: "600", margin: "12px"}}>Extra Effect Types</h4>
                                                <div className="borderBlack" style={{display:"flex"}}>
                                                    {extra_effects_list.map((extra_effect) => (
                                                        <NavLink to={`/extraeffects/${extra_effect.id}`} className="nav-link2 glow2">
                                                            <h5 title={extra_effect.rules}
                                                                style={{fontWeight: "400",
                                                                height: "22px",
                                                                margin: "0px 5px 20px 15px"}}>
                                                                {extra_effect.name} *
                                                            </h5>
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </>
                                            ) : null}
                                        </div>
                                        <div className="none">
                                            { searchGroups.length > 0?
                                                <SearchGroupPanel
                                                    searchGroups={searchGroups}
                                                    cardClass={card.card_class}
                                                />: null
                                            }
                                            {!noCardTip?
                                                <NavLink to={`/cardtips/${card_number}`} className="nav-link no-pad">
                                                    <div className="bigNoClassCreate margin-top-8 glow3">
                                                        <h1 style={{marginBottom: "10px", marginTop: "10px"}}>
                                                            Go To Card Tips ({tipCount})
                                                        </h1>
                                                    </div>
                                                </NavLink>
                                                :
                                                account && account.roles.includes("admin")?
                                                    <NavLink to={`/tipcreate/${card_number}`} className="nav-link no-pad">
                                                        <div className="bigNoClassCreate margin-top-8 glow3">
                                                            <h1 style={{marginBottom: "10px", marginTop: "10px"}}>
                                                                Create Card Tips
                                                            </h1>
                                                        </div>
                                                    </NavLink>:null
                                            }
                                            {!noCardRuling?
                                                <NavLink to={`/cardtips/${card_number}`}
                                                    className="nav-link"
                                                >
                                                    <h1 className="margin-10">Go To Card Rulings  *</h1>
                                                </NavLink>
                                                :null
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="hidden2 media-display">
                                    <div style={{margin: "5% 0%"}}>
                                        <h1 className="centered-h1">Related Cards</h1>
                                        <div className="cd-inner">
                                            <div className="cd-inner card-pool-fill3">
                                                {relatedCardsList.slice(0,8).map((relatedCard) => {
                                                    return (
                                                        <NavLink to={`/cards/${relatedCard.card_number}`}>
                                                            <img
                                                                className="cd-related-card"
                                                                title={relatedCard.name}
                                                                src={relatedCard.picture_url ? relatedCard.picture_url : "https://i.imgur.com/krY25iI.png"}
                                                                alt={relatedCard.name}/>
                                                        </NavLink>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        {relatedCardsList.length > 8?
                                        <div className="flex-content">
                                            <NavLink className="nav-link"
                                                to={`/relatedcards/${card.hero_id}${card.card_number}`}
                                                style={{marginBottom: "-10px"}}
                                            >
                                                <button >
                                                    Show All Cards ({relatedCardsList.length})
                                                </button>
                                            </NavLink>
                                        </div>: null
                                        }
                                        <div className="media-display">
                                            <div className="margin-top-20">
                                                <SearchGroupPanel
                                                    searchGroups={searchGroups}
                                                    cardClass={card.card_class}
                                                />
                                            </div>
                                            {!noCardTip?
                                                <NavLink to={`/cardtips/${card_number}`} className="nav-link no-pad">
                                                    <div className="bigNoClassCreate margin-top-10 glow3">
                                                        <h1 style={{marginBottom: "10px", marginTop: "10px"}}>
                                                            Go To Card Tips ({tipCount})
                                                        </h1>
                                                    </div>
                                                </NavLink>
                                                :
                                                account && account.roles.includes("admin")?
                                                    <NavLink to={`/tipcreate/${card_number}`} className="nav-link no-pad">
                                                        <div className="bigNoClassCreate margin-top-10 glow3">
                                                            <h1 style={{marginBottom: "10px", marginTop: "10px"}}>
                                                                Create Card Tips
                                                            </h1>
                                                        </div>
                                                    </NavLink>:null
                                            }
                                            {!noCardRuling?
                                                <NavLink to={`/cardtips/${card_number}`} className="nav-link no-pad">
                                                    <div className="bigNoClassCreate margin-top-10">
                                                        <h1 style={{marginBottom: "10px", marginTop: "10px"}}>
                                                            Go To Card Rulings *
                                                        </h1>
                                                    </div>
                                                </NavLink>
                                                :null
                                            }
                                        </div>
                                        <div className="cd-inner" style={{marginTop: "3%"}}>
                                            <button
                                                className="left button100 heightNorm"
                                                style={{ textAlign: "center"}}
                                                onClick={getRandomCard}
                                            >
                                                Random Card
                                            </button>
                                            { account && account.roles.includes("admin")?
                                                <NavLink to={`/cards/${card_number}/edit`}>
                                                    <button className="left red left">
                                                        Edit Card
                                                    </button>
                                                </NavLink>:
                                            null}
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>:
                <ErrorPage path={"/cards/"}/>
            }
        </>
    );
}

export default CardDetailPage;
